import { createUserManager } from "redux-oidc";
import { v1Api } from "apis";
import { UserManager, WebStorageStateStore } from "oidc-client";

const userManagerConfig = {
  client_id: "",
  redirect_uri: `${window.location.origin}/login-callback`,
  response_type: "code",
  scope: "openid profile email civicclerk.api notifications.api",
  authority: "",
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `${window.location.port}` : ""}/silent-renew`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: `${window.location.origin}/logout-callback`,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: false,
};

class UserManagerCP {
  userManager: UserManager | null;

  constructor() {
    this.userManager = null;
  }
  loadUserManager: () => void = async () => {
    if (!this.userManager) {
      try {
        const response = await v1Api.get(
          `/Settings/GetIdentityServerConfiguration`
        );
        const idsConfig = response.data;
        userManagerConfig.client_id = idsConfig.clientId;
        userManagerConfig.authority = idsConfig.apiUrl;

        this.userManager = createUserManager(userManagerConfig);
      } catch (e) {
        this.userManager = null;
      }
    }
  };
}

const UserManagerInstance = new UserManagerCP();

export default UserManagerInstance;
