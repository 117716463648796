import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import reducer from "../reducers";
import UserManagerCP from "../utils/UserManagerCP";
import ReduxThunk from "redux-thunk";
import createOidcMiddleware, { loadUser } from "redux-oidc";

const storeForTypes = configureStore({
  reducer: reducer,
});

export type RootState = ReturnType<typeof storeForTypes.getState>;
export type AppDispatch = typeof storeForTypes.dispatch;

const loadAsyncStore = async (): Promise<EnhancedStore<RootState> | null> => {
  await UserManagerCP.loadUserManager();
  if (UserManagerCP.userManager) {
    const oidcMiddleware = createOidcMiddleware(UserManagerCP.userManager);

    const store = configureStore({
      reducer: reducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ["redux-oidc/USER_FOUND"],
            ignoredPaths: ["oidc.user"],
          },
        }).concat([ReduxThunk, oidcMiddleware]),
    });

    loadUser(store, UserManagerCP.userManager);

    return store;
  }
  return null;
};

export default loadAsyncStore;
