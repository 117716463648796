import { reducer as oidcReducer } from "redux-oidc";
import ACTION from "actions/actionNames";
import { Action } from "actions";
import {
  EventCategory,
  EventMeeting,
  EventDetails,
  EventMedia,
  EventFilters,
} from "types/types.event";
import { AgendaEvent } from "types/types.agenda";
import { SearchResult } from "types/types.search";
import { PortalCustomization } from "types/types.customization";
import eventFilterModel from "models/EventFilter";
import { JwplayerVideo } from "types/types";

const reducers = {
  isLoading: (isLoading = false, action: Action<boolean>): boolean => {
    if (action.type === ACTION.SET_LOADING) {
      return action.payload || false;
    }
    return isLoading;
  },
  scrollToToday: (scrollToToday = false, action: Action<boolean>): boolean => {
    if (action.type === ACTION.SET_SCROLL_TO_TODAY) {
      return action.payload || false;
    }
    return scrollToToday;
  },
  eventCategories: (
    eventCategories: EventCategory[] | null = null,
    action: Action<EventCategory[]>
  ): EventCategory[] | null => {
    if (action.type === ACTION.GET_CATEGORIES) {
      return action.payload || null;
    }
    return eventCategories;
  },
  eventsList: (
    eventsList: EventMeeting[] | null = null,
    action: Action<EventMeeting[]>
  ): EventMeeting[] | null => {
    if (action.type === ACTION.GET_EVENTS_LIST) {
      return action.payload || null;
    }
    if (action.type === ACTION.GET_NEXT_PAST_EVENTS_LIST) {
      let events = action.payload || null;
      if (events && eventsList) {
        events = events?.concat(...eventsList);
      }
      return events;
    }
    if (action.type === ACTION.CLEAN_EVENT_LIST) {
      return null;
    }
    return eventsList;
  },
  eventDetails: (
    event: EventDetails | null = null,
    action: Action<EventDetails>
  ): EventDetails | null => {
    if (action.type === ACTION.GET_EVENT_DETAILS) {
      return action.payload || null;
    }
    if (action.type === ACTION.CLEAN_EVENT_DETAILS) {
      return null;
    }
    return event;
  },
  agendaEvent: (
    agenda: AgendaEvent | null = null,
    action: Action<AgendaEvent>
  ): AgendaEvent | null => {
    if (action.type === ACTION.GET_AGENDA_EVENT) {
      return action.payload || null;
    }
    if (action.type === ACTION.CLEAN_AGENDA_EVENT) {
      return null;
    }
    return agenda;
  },
  eventMedia: (
    eventMedia: EventMedia | null = null,
    action: Action<EventMedia>
  ): EventMedia | null => {
    if (action.type === ACTION.GET_EVENT_MEDIA) {
      return action.payload || null;
    }
    if (action.type === ACTION.CLEAN_EVENT_MEDIA) {
      return null;
    }
    return eventMedia;
  },
  jwplayerEventVideo: (
    jwplayerVideo: JwplayerVideo | null = null,
    action: Action<JwplayerVideo>
  ): JwplayerVideo | null => {
    if (action.type === ACTION.SET_JWPLAYER_VIDEO) {
      if (action.payload) {
        return {
          ...jwplayerVideo,
          ...action.payload,
        };
      }
      return null;
    }
    if (action.type === ACTION.CLEAN_JWPLAYER_VIDEO) {
      return null;
    }
    return jwplayerVideo;
  },
  oidc: oidcReducer,
  eventFilters: (
    filters: EventFilters = { ...eventFilterModel },
    action: Action<EventFilters>
  ): EventFilters => {
    if (action.type === ACTION.UPDATE_EVENT_FILTERS) {
      if (action.payload) {
        return {
          ...filters,
          ...action.payload,
        };
      }
    }
    return filters;
  },
  searchResults: (
    searchResults: SearchResult[] | null = null,
    action: Action<SearchResult[]>
  ): SearchResult[] | null => {
    if (action.type === ACTION.GET_SEARCH_RESULTS) {
      return action.payload || null;
    }
    if (action.type === ACTION.GET_NEXT_SEARCH_RESULTS) {
      let results = action.payload || null;
      if (results && searchResults) {
        results = searchResults?.concat(results);
      }
      return results;
    }
    if (action.type === ACTION.CLEAR_SEARCH_RESULTS) {
      return null;
    }
    return searchResults;
  },
  portalCustomization: (
    portalCustomization: PortalCustomization | null = null,
    action: Action<PortalCustomization>
  ): PortalCustomization | null => {
    if (action.type === ACTION.GET_PORTAL_CUSTOMIZATION) {
      return action.payload || null;
    }
    return portalCustomization;
  },
  selectedDate: (
    date: string | null = null,
    action: Action<string>
  ): string | null => {
    if (action.type === ACTION.UPDATE_SELECTED_DATE) {
      return action.payload || null;
    }
    return date;
  },
  isNextLink: (
    isNextLink: boolean | null = false,
    action: Action<boolean>
  ): boolean | null => {
    if (action.type === ACTION.UPDATE_NEXT_LINK) {
      return action.payload || false;
    }
    return isNextLink;
  },
  isPastLink: (
    isPastLink: boolean | null = false,
    action: Action<boolean>
  ): boolean | null => {
    if (action.type === ACTION.UPDATE_PAST_LINK) {
      return action.payload || false;
    }
    return isPastLink;
  },
};

export default reducers;
