import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import loadable from "@loadable/component";
import Loader from "@civicplus/preamble-ui/lib/Loader";

const LoadableErrorPage = loadable(
  () => import("@civicplus/preamble-ui/lib/ErrorPage"),
  {
    fallback: <Loader />,
  }
);

const styles = (theme: Theme) =>
  createStyles({
    error: {
      padding: theme.spacing(5),
    },
  });
interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode;
}
type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <LoadableErrorPage
          id="error-page"
          title="Sorry, something went wrong!"
          onClick={() => window.location.reload()}
          linkDescription="Reload"
          severity="error"
          className={this.props.classes.error}
        >
          Please reload the page and try again. If you continue to see this
          message, please note the steps you are taking and contact CivicPlus
          support.
        </LoadableErrorPage>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
