import { v1Api } from "apis";
import ACTION from "./actionNames";
import {
  EventFilters,
  EventMeeting,
  EventDetails,
  EventMedia,
  EventCategory,
  Bookmark,
} from "types/types.event";
import { AgendaEvent } from "types/types.agenda";
import { SearchResult, SearchSubFilter } from "types/types.search";
import { PortalCustomization } from "types/types.customization";
import { ApiResponseValueKey, JwplayTracks, JwplayerVideo } from "types/types";
import { AxiosResponse } from "axios";
import { AppDispatch } from "app/store";

export interface Action<T> {
  type: number;
  payload?: T;
}

type Params = {
  $filter?: string;
  $orderby?: string;
  $skip?: string;
};
type GetEventConfig = {
  params: Params;
};

export const getEventsListAsync = (
  filters: EventFilters | null = null,
  loadPastEvents: boolean,
  loadNextEvents: boolean,
  initialLoad: boolean
) => {
  return async (
    dispatch: AppDispatch
  ): Promise<Action<EventMeeting[] | undefined>> => {
    const getAndOperator = () => {
      return config.params.$filter ? " and" : "";
    };
    const config: GetEventConfig = {
      params: {
        $filter: "",
        $orderby: "startDateTime",
      },
    };

    const today = filters?.calendarDate
      ? new Date(filters.calendarDate)
      : new Date();
    today.setHours(0, 0, 0);
    if (!filters?.calendarDate) {
      today.setDate(today.getDate());
    }

    if (filters) {
      if (filters.categories?.length) {
        config.params.$filter += `categoryId in (${filters.categories.join()})`;
      }
      if (!loadPastEvents) {
        dispatch(cleanSearchResults());
        if (filters.date?.from) {
          const fromDate = new Date(filters.date.from);
          // We drop the timezone as the API saves all events without timezones. This adjustment
          // will need to be fixed if the Event API saves in UTC.
          fromDate.setUTCHours(0, 0, 0);
          config.params.$filter += `${getAndOperator()} startDateTime ge ${getCorrectDate(
            fromDate
          )}`;
        }
        if (filters.date?.to) {
          const toDate = new Date(filters.date.to);
          // We drop the timezone as the API saves all events without timezones. This adjustment
          // will need to be fixed if the Event API saves in UTC.
          toDate.setUTCHours(23, 59, 59);

          config.params.$filter += `${getAndOperator()} startDateTime le ${getCorrectDate(
            toDate
          )}`;
        }
        // If there is only an end date filter, we reverse the list orders.
        if (!filters.date?.from && filters.date?.to) {
          config.params.$orderby = `startDateTime desc`;
        }
        // If there are no date filters, we use today as the starting point
        if (!filters.date?.from && !filters.date?.to) {
          config.params.$filter += `${getAndOperator()} startDateTime ge ${getCorrectDate(
            today
          )}`;
        }
        if (loadNextEvents && filters.skip) {
          config.params.$skip = `${filters.skip}`;
        }
      } else {
        if (filters.loadPastDate?.from) {
          config.params.$filter += `${getAndOperator()} startDateTime ge ${getCorrectDate(
            new Date(filters.loadPastDate.from)
          )}`;
        }
        if (filters.loadPastDate?.to) {
          config.params.$filter += `${getAndOperator()} startDateTime le ${getCorrectDate(
            new Date(filters.loadPastDate.to)
          )}`;
        }
        if (filters.loadPastDate?.skip) {
          config.params.$skip = `${filters.loadPastDate.skip}`;
        }
        config.params.$orderby = `startDateTime desc, EventName desc`;
      }
    }
    if (!config.params.$filter) {
      // If there is no filters, return the last events from Yesterday
      config.params.$filter += `${getAndOperator()} startDateTime ge ${getCorrectDate(
        today
      )}`;
    }

    const requestUrl = `/Events`;
    const initialLoadPastEventPageSize = 5;

    if (
      initialLoad &&
      !filters?.calendarDate &&
      !filters?.categories?.length &&
      !filters?.date?.from &&
      !filters?.date?.to
    ) {
      config.params = {};

      const pastEventsUrl = `/Events?$filter=startDateTime le ${getCorrectDate(
        today
      )}&$orderby=EventDate desc, EventName desc&$top=${initialLoadPastEventPageSize}`;

      const futureEventsUrl = `/Events?$filter=startDateTime gt ${getCorrectDate(
        today
      )}&$orderby=EventDate asc, EventName asc`;

      let initiallyLoadedEvents: EventMeeting[] = [];

      const pastTwoEvents = await v1Api.get(pastEventsUrl, config);
      if (pastTwoEvents.data?.value) {
        initiallyLoadedEvents = initiallyLoadedEvents.concat(
          pastTwoEvents.data.value
        );
      }

      const futureTwoEvents = await v1Api.get(futureEventsUrl, config);
      if (futureTwoEvents.data?.value) {
        initiallyLoadedEvents = initiallyLoadedEvents.concat(
          futureTwoEvents.data.value
        );
      }

      dispatch(updateNextLink(true));
      updatePastLink(initiallyLoadedEvents.length === 0 ? true : false);

      return dispatch({
        type: ACTION.GET_EVENTS_LIST,
        payload:
          initiallyLoadedEvents.length > 0 ? initiallyLoadedEvents : undefined,
      });
    }

    return v1Api
      .get(requestUrl, config)
      .then(({ data }: AxiosResponse<ApiResponseValueKey<EventMeeting[]>>) => {
        if (!loadPastEvents) {
          // Check if the result has a nextLink value for pagination purpose
          if (
            initialLoad &&
            !filters?.calendarDate &&
            !filters?.categories?.length &&
            !filters?.date?.from &&
            !filters?.date?.to
          ) {
            dispatch(updateNextLink(true));
          } else {
            dispatch(
              updateNextLink(
                data["@odata.nextLink" as keyof typeof data] ? true : false
              )
            );
          }
        }
        if (!loadNextEvents) {
          // Check if the result has values for pagination purpose
          dispatch(updatePastLink(data?.value.length === 0 ? true : false));
        }
        const prevNextEvents = loadPastEvents || loadNextEvents;
        return dispatch({
          type: !prevNextEvents
            ? ACTION.GET_EVENTS_LIST
            : ACTION.GET_NEXT_PAST_EVENTS_LIST,
          payload: data?.value || null,
        });
      });
  };
};

function getCorrectDate(date: Date) {
  // Using entire ISO string to allow pagination to function properly.
  const isoDate = date.toISOString();
  return isoDate;
}

export const cleanEventList = (): Action<undefined> => {
  return {
    type: ACTION.CLEAN_EVENT_LIST,
  };
};

export const getEventDetails = (
  eventDetails: EventDetails
): Action<EventDetails> => {
  return {
    type: ACTION.GET_EVENT_DETAILS,
    payload: eventDetails,
  };
};

export const getEventDetailsAsync = (eventId: number) => {
  return (dispatch: AppDispatch): Promise<Action<EventDetails>> => {
    return v1Api
      .get(`/Events/${eventId}`)
      .then(({ data }: AxiosResponse<EventDetails>) =>
        dispatch(getEventDetails(data))
      );
  };
};
export const cleanEventDetails = (): Action<undefined> => {
  return {
    type: ACTION.CLEAN_EVENT_DETAILS,
  };
};

export const getAgendaEvent = (agenda: AgendaEvent): Action<AgendaEvent> => {
  return {
    type: ACTION.GET_AGENDA_EVENT,
    payload: agenda,
  };
};
export const cleanAgendaEvent = (): Action<undefined> => {
  return {
    type: ACTION.CLEAN_AGENDA_EVENT,
  };
};

export const getAgendaEventAsync = (
  id: number
):
  | Action<AgendaEvent>
  | ((dispatch: AppDispatch) => Promise<Action<AgendaEvent>>) => {
  if (!id) {
    return getAgendaEvent({
      id: -1,
      items: [],
      publishedFiles: [],
      isPublish: false,
    });
  }
  return (dispatch: AppDispatch): Promise<Action<AgendaEvent>> => {
    return v1Api
      .get(`/Meetings/${id}`)
      .then(({ data }: AxiosResponse<AgendaEvent>) =>
        dispatch(getAgendaEvent(data))
      );
  };
};

export const getEventMedia = (eventMedia: EventMedia): Action<EventMedia> => {
  return {
    type: ACTION.GET_EVENT_MEDIA,
    payload: eventMedia,
  };
};

export const updateSelectedDate = (date: string): Action<string> => {
  return {
    type: ACTION.UPDATE_SELECTED_DATE,
    payload: date,
  };
};

export const updateNextLink = (isNextLink: boolean): Action<boolean> => {
  return {
    type: ACTION.UPDATE_NEXT_LINK,
    payload: isNextLink,
  };
};
export const updatePastLink = (isPastLink: boolean): Action<boolean> => {
  return {
    type: ACTION.UPDATE_PAST_LINK,
    payload: isPastLink,
  };
};

export const getEventMediaAsync = (id: number) => {
  return (dispatch: AppDispatch): Promise<Action<EventMedia>> => {
    return v1Api
      .get(`/EventsMedia/${id}`)
      .then(({ data }: AxiosResponse<EventMedia>) =>
        dispatch(
          getEventMedia({
            id: data?.id,
            videoUrl: data?.videoUrl,
            eventBookmarks: data?.eventBookmarks,
            externalVideoUrl: data?.externalVideoUrl,
            isLiveStreaming: data?.isLiveStreaming,
            eventCCKey: data?.eventCCKey,
            pauseMessage: data?.pauseMessage,
            streamingStatus: data?.streamingStatus,
            transcriptionUrl: data?.transcriptionUrl,
            closedCaptionUrl: data?.closedCaptionUrl,
            closedCaptionTracks: data?.closedCaptionTracks,
            defaultFileName: data?.defaultFileName,
            showTranscoding: data.showTranscoding,
            audioDescriptionUrl: data?.audioDescriptionUrl,
          })
        )
      );
  };
};

export const cleanEventMedia = (): Action<undefined> => {
  return {
    type: ACTION.CLEAN_EVENT_MEDIA,
  };
};

export const setJwplayerVideo = (
  jwplayerVideo: JwplayerVideo | null = null
): Action<JwplayerVideo | null> => {
  const tracks: JwplayTracks[] = [];
  if (
    jwplayerVideo?.bookmark?.length &&
    jwplayerVideo?.tracks?.filter(track => track.kind === "chapters").length ===
      0
  ) {
    let vttString = "WEBVTT\n\n";
    let i = 1;

    const makeBookmarks = (bookmark: Bookmark) => {
      vttString += `Chapter ${i}\n${bookmark.markerTimeHHMMSSFormat}.000  --> ${bookmark.markerTimeHHMMSSFormat}.000\n${bookmark.markerTitle}\n\n`;
      bookmark.additionalBookmarks?.forEach(makeBookmarks);
      i++;
    };

    jwplayerVideo?.bookmark?.forEach(makeBookmarks);

    const chapterFile = URL.createObjectURL(
      new Blob([vttString], {
        type: "text/vtt",
      })
    );

    tracks.push({
      file: chapterFile,
      kind: "chapters",
    });
  }

  if (jwplayerVideo?.tracks) {
    tracks.push(...jwplayerVideo.tracks);
  }

  return {
    type: ACTION.SET_JWPLAYER_VIDEO,
    payload: {
      ...jwplayerVideo,
      tracks,
    },
  };
};

export const cleanJwplayerVideo = (): Action<undefined> => {
  return {
    type: ACTION.CLEAN_JWPLAYER_VIDEO,
  };
};

export const getCategoriesAsync = () => {
  return (dispatch: AppDispatch): Promise<Action<EventCategory>> => {
    return v1Api
      .get("/EventCategories")
      .then(({ data }: AxiosResponse<ApiResponseValueKey<EventCategory>>) =>
        dispatch({
          type: ACTION.GET_CATEGORIES,
          payload: data.value,
        })
      );
  };
};

export const updateEventFilters = (
  filters: EventFilters | undefined = undefined
): Action<EventFilters> => {
  return {
    type: ACTION.UPDATE_EVENT_FILTERS,
    payload: filters,
  };
};

export const getSearchResultsAsync = (
  filters: EventFilters,
  skipAzureSearch: number
) => {
  return (dispatch: AppDispatch): Promise<Action<SearchResult | undefined>> => {
    dispatch(cleanEventList());
    const subFilter = filters.contentTypes
      ? filters.contentTypes.join(",")
      : "";
    const agendaFileType = "";
    let fromDate = "";
    let toDate = "";

    if (filters.date?.from != null) {
      fromDate = new Date(filters.date.from).toDateString();
    }
    if (filters.date?.to != null) {
      toDate = new Date(filters.date.to).toDateString();
    }
    let url = `/Search/GetPortalSearch(search='{search}',categoryIds='{categoryIds}',startDate='{startDate}',endDate='{endDate}',skipAzureSearch='{skipAzureSearch}',subFilter='{subFilter}',agendaFileType='{agendaFileType}')?search=${filters.search}`;

    if (filters.categories) {
      url += `&categoryIds=(${filters.categories?.join()})`;
    }
    if (fromDate) {
      url += `&startDate=${fromDate}`;
    }
    if (toDate) {
      url += `&endDate=${toDate}`;
    }
    if (subFilter) {
      url += `&subFilter=${subFilter}`;
    }
    if (
      agendaFileType &&
      subFilter &&
      subFilter === SearchSubFilter.agendaFiles
    ) {
      url += `&agendaFileType=${agendaFileType}`;
    }
    if (skipAzureSearch) {
      url += `&skipAzureSearch=${skipAzureSearch}`;
    }
    if (filters.skip) {
      url += `&$skip=${filters.skip}`;
    }

    return v1Api.get(url).then(({ data }) =>
      dispatch({
        type:
          !filters.skip && !skipAzureSearch
            ? ACTION.GET_SEARCH_RESULTS
            : ACTION.GET_NEXT_SEARCH_RESULTS,
        payload: data?.value,
      })
    );
  };
};

export const cleanSearchResults = (): Action<undefined> => {
  return {
    type: ACTION.CLEAR_SEARCH_RESULTS,
  };
};

export const getPortalCustomization = (
  portalCustomization: PortalCustomization
): Action<PortalCustomization> => {
  return {
    type: ACTION.GET_PORTAL_CUSTOMIZATION,
    payload: portalCustomization,
  };
};

export const getPortalCustomizationAsync = () => {
  return (dispatch: AppDispatch): Promise<Action<PortalCustomization>> => {
    return v1Api
      .get("/Settings/GetPublicPortalCustomizations")
      .then(({ data }: AxiosResponse<PortalCustomization>) =>
        dispatch(getPortalCustomization(data))
      );
  };
};

export const setLoading = (isLoading: boolean): Action<boolean> => {
  return {
    type: ACTION.SET_LOADING,
    payload: isLoading,
  };
};

export const setScrollToToday = (scrollToToday: boolean): Action<boolean> => {
  return {
    type: ACTION.SET_SCROLL_TO_TODAY,
    payload: scrollToToday,
  };
};
