import { MeetingFile } from "components/EventDetails/MeetingFiles/interfaces";
import { EventLocation } from "./types.event";

interface ResourceLink {
  resourceId: number;
  path: string;
  isGoogleAttachment: boolean;
}
export interface itemResult {
  id: number;
  score: number;
  icon: string;
  type: string;
  name: string;
  createdBy: string;
  createdOn: Date;
  meetingDate: string;
  resourceLink: ResourceLink;
  fileContent: string[];
  serviceType: number;
}

export interface eventResult {
  id: number;
  score: number;
  icon: string;
  type: string;
  name: string;
  createdBy: string;
  createdOn: Date;
  meetingDate: string;
  resourceLink: ResourceLink;
  liveIsCurrentlyStreaming: boolean;
  hasMedia: boolean;
  externalMediaUrl?: string;
  eventLocation?: EventLocation;
  categoryName: string;
  publishedFiles: MeetingFile[];
  defaultPublicPortalPage: number;
}

export interface SearchResult {
  agenda: Array<itemResult>;
  agendaFiles: Array<itemResult>;
  event: eventResult;
  items: Array<itemResult>;
  attachments: Array<itemResult>;
  videoTimestamps: Array<itemResult>;
  closedCaptions: Array<itemResult>;
}

export enum SearchSubFilter {
  showAll = "ShowAll",
  agendas = "Agendas",
  events = "Events",
  items = "Items",
  agendaFiles = "AgendaFiles",
  attachments = "Attachments",
  timestamps = "Timestamps",
  closedCaptions = "ClosedCaptions",
  translations = "Translations",
}

export enum AgendaFileFilter {
  showAll = "ShowAll",
  agenda = "Agenda",
  packet = "Packet",
  minutes = "Minutes",
  other = "Other",
  notice = "Notice",
}
