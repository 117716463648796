enum ACTION {
  GET_EVENTS_LIST,
  GET_NEXT_PAST_EVENTS_LIST,
  CLEAN_EVENT_LIST,
  GET_EVENT_DETAILS,
  CLEAN_EVENT_DETAILS,
  GET_AGENDA_EVENT,
  CLEAN_AGENDA_EVENT,
  GET_EVENT_MEDIA,
  CLEAN_EVENT_MEDIA,
  SET_JWPLAYER_VIDEO,
  CLEAN_JWPLAYER_VIDEO,
  GET_CATEGORIES,
  UPDATE_EVENT_FILTERS,
  UPDATE_SELECTED_DATE,
  UPDATE_NEXT_LINK,
  UPDATE_PAST_LINK,
  GET_SEARCH_RESULTS,
  GET_NEXT_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  GET_PORTAL_CUSTOMIZATION,
  SET_LOADING,
  SET_SCROLL_TO_TODAY,
}

export default ACTION;
